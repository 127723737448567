
























































import { Vue, Component } from "vue-property-decorator";

@Component
export default class Login extends Vue {
  form = {
    email: "",
    password: "",
  };
  errorMessages = "Incorrect login info";
  snackbar = false;
  color = "general";
  showPassword = false;

  onSubmit(): void {
    this.$store
      .dispatch("login", this.form)
      .then(() => this.$router.push("/"))
      .catch(err => {
        console.log(err);
        this.snackbar = true;
      });
  }

  mounted() {
    const inputs = document.getElementsByTagName('input');
    inputs.item(0).focus();
  }

  metaInfo() {
    return {
      title: "Super Secret | Login",
    };
  }
}
